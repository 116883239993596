import axios from "axios";
import checkAuth from "../../app/auth";
const baseURL = process.env.REACT_APP_BASE_URL;

// Fonction pour obtenir le token et l'ajouter à l'en-tête d'autorisation
const getTokenAndSetHeaders = () => {
  const token = checkAuth(); // Obtenez le token
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Ajoutez le token à l'en-tête d'autorisation
  }
};

// Fonction pour obtenir toutes les batch
const addBatchService = async (data) => {
  getTokenAndSetHeaders(); // Appelez la fonction pour obtenir le token et l'ajouter à l'en-tête
    try {
      const response = await axios.post(`${baseURL}/batch`, data);
      if (response.status === 200) {
        return { success: true, id: response.data.batch_id };
      } else {
        return { success: false, status: response.status, message: response.data.message };
      }
    } catch (error) {
      if (error.response) {
        return { success: false, status: error.response.status, message: error.response.data };
      } else {
        return { success: false, message: 'Une erreur s\'est produite lors de la requête.' };
      }
    }
  };


// Fonction pour obtenir toutes les batch
const getAllBatchService = async () => {
  getTokenAndSetHeaders(); // Appelez la fonction pour obtenir le token et l'ajouter à l'en-tête
  try {
    const response = await axios.get(`${baseURL}/batch`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour obtenir une offre spécifique par son ID
const getBatchByIdService = async (offerId) => {
  getTokenAndSetHeaders();
  try {
    const response = await axios.get(`${baseURL}/batch/${offerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour mettre à jour une offre spécifique
const updateBatchService = async (offerId, updatedOfferData) => {
  getTokenAndSetHeaders();
  try {
    const response = await axios.put(`${baseURL}/batch/${offerId}`, updatedOfferData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour supprimer une offre spécifique
const deleteBatchService = async (offerId) => {
  getTokenAndSetHeaders();
  try {
    const response = await axios.delete(`${baseURL}/batch/${offerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const BatchFilterService = async (data) => {
  getTokenAndSetHeaders();
  try {
    const response = await axios.post(`${baseURL}/batch-filtre`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour obtenir toutes les batch
const addBatchVeriferNameService = async (data) => {
  getTokenAndSetHeaders(); // Appelez la fonction pour obtenir le token et l'ajouter à l'en-tête
    try {
      const response = await axios.post(`${baseURL}/batch-name-verifier`, data);
      if (response.status === 200) {
        return { success: true, id: response.data };
      } else {
        return { success: false, status: response.status, message: response.data.message };
      }
    } catch (error) {
      if (error.response) {
        return { success: false, status: error.response.status, message: error.response.data };
      } else {
        return { success: false, message: 'Une erreur s\'est produite lors de la requête.' };
      }
    }
  };

  // Fonction pour obtenir toutes les batch
const getAllChartBatchService = async () => {
  getTokenAndSetHeaders(); // Appelez la fonction pour obtenir le token et l'ajouter à l'en-tête
  try {
    const response = await axios.get(`${baseURL}/batch/chart-test`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export { 
    addBatchService, 
    getAllBatchService, 
    getBatchByIdService, 
    updateBatchService , 
    deleteBatchService , 
    BatchFilterService, 
    getAllChartBatchService
  };
