import axios from "axios";

const checkAuth = () => {
    // Récupérer l'objet token depuis le localStorage
    const tokenObject = JSON.parse(localStorage.getItem("token"));

    // Vérifier si tokenObject est null
    if (!tokenObject) {
        const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"];
        const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r));
        
        if (!isPublicPage) {
            // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
            window.location.href = '/login';
        }
        return null; // Retourner null si aucun token n'est présent
    }

    // Extraire le token de l'objet token si présent
    const TOKEN = tokenObject.token;

    // Ajouter le token à l'en-tête d'Authorization
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;

    axios.interceptors.request.use(function (config) {
        // UPDATE: Ajouter ce code pour afficher l'indicateur de chargement global
        document.body.classList.add('loading-indicator');
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        // UPDATE: Ajouter ce code pour masquer l'indicateur de chargement global
        document.body.classList.remove('loading-indicator');
        return response;
    }, function (error) {
        document.body.classList.remove('loading-indicator');
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        }
        return Promise.reject(error);
    });

    // Retourner le token pour une utilisation éventuelle
    return TOKEN;
}

export default checkAuth;
