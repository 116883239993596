import axios from "axios";
import checkAuth from "../../app/auth";
const baseURL = process.env.REACT_APP_BASE_URL;

// Fonction pour obtenir le token et l'ajouter à l'en-tête d'autorisation
const getTokenAndSetHeaders = () => {
  const token = checkAuth(); // Obtenez le token
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Ajoutez le token à l'en-tête d'autorisation
  }
};

// Fonction pour obtenir toutes les offres
const addOfferNotice = async (data) => {
  getTokenAndSetHeaders(); // Appelez la fonction pour obtenir le token et l'ajouter à l'en-tête
  try {
    const response = await axios.post(`${baseURL}/offer-notice`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

// Fonction pour obtenir toutes les offres
const getAllOfferNotice = async (page, filters) => {
  getTokenAndSetHeaders(); // Appelez la fonction pour obtenir le token et l'ajouter à l'en-tête
  try {
    const response = await axios.get(`${baseURL}/offer-notice`, {
      params: { page, ...filters }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour obtenir une offre spécifique par son ID
const getOfferNoticeById = async (offerId) => {
  getTokenAndSetHeaders();
  try {
    const response = await axios.get(`${baseURL}/offer-notice/${offerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour mettre à jour une offre spécifique
const updateOfferNotice = async (offerId, updatedOfferData) => {
  getTokenAndSetHeaders();
  try {
    const response = await axios.put(`${baseURL}/offer-notice/${offerId}`, updatedOfferData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour supprimer une offre spécifique
const deleteOfferNotice = async (offerId) => {
  getTokenAndSetHeaders();
  try {
    const response = await axios.delete(`${baseURL}/offer-notice/${offerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { getAllOfferNotice, getOfferNoticeById, updateOfferNotice, deleteOfferNotice , addOfferNotice};
