import { createSlice , createAsyncThunk} from "@reduxjs/toolkit";
import { addBatchService, getAllBatchService, getBatchByIdService, updateBatchService , deleteBatchService  } from "../../batch/Batch";

export const BatchSlice = createSlice({
    name:'batch',
    initialState: {
        loading: false,
        error: null,
        batchs: []
    },
    reduces: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBatchById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBatchById.fulfilled, (state, action) => {
              state.loading = false;
              state.batchs = action.payload;
            })
            .addCase(fetchBatchById.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            });
    }
})

export const fetchBatchById = createAsyncThunk(
    "batch/fetchById",
    async (batchId, { dispatch, rejectWithValue}) => {
        try {
            const response = await getBatchByIdService(batchId)
            return response
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export default BatchSlice.reducer;