import { createSlice , createAsyncThunk} from "@reduxjs/toolkit";
import { addOfferNotice ,getAllOfferNotice, getOfferNoticeById, updateOfferNotice, deleteOfferNotice} from "../../offerNotice/offerNotice";

export const offerNoticeSlice = createSlice({
    name: 'offerNotice',
    initialState: {
        loading: false,
        error: null, // Ajoutez une clé pour stocker les erreurs
        offerNotices: [] 
    },
    reducers: {
        addOfferStart: (state) => {
            state.loading = true;
            state.error = null; // Réinitialisez l'erreur lorsqu'une nouvelle action commence
        },
        addOfferSuccess: (state) => {
            state.loading = false;
        },
        addOfferFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload.error; // Stockez l'erreur dans le state
        },
        setOfferNotices: (state, action) => {
            state.offerNotices = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error =action.error
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchOfferNoticeById.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(fetchOfferNoticeById.fulfilled, (state, action) => {
            state.loading = false;
            state.offerNotices = action.payload;
          })
          .addCase(fetchOfferNoticeById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          });
      },
});

export const { addOfferStart, addOfferSuccess, addOfferFailure , setOfferNotices, setLoading, setError} = offerNoticeSlice.actions;

export const addOffer = (offerData) => async (dispatch) => {
    dispatch(addOfferStart());
    try {
        const response = await addOfferNotice(offerData)
        dispatch(addOfferSuccess());
        console.log(response);
    } catch (error) {
        dispatch(addOfferFailure({ error: error.message }));
    }
};

export const fetchAllOfferNotices = () => async (dispatch) => {
    dispatch(setLoading(true)); // Mettre le chargement à true
    try {
        const response = await getAllOfferNotice();
        dispatch(setOfferNotices(response.message));
        console.log(response) // Mettre à jour les offres dans le state
    } catch (error) {
        dispatch(setError(error.message)); // Mettre à jour l'erreur dans le state
    } finally {
        dispatch(setLoading(false)); // Mettre le chargement à false une fois terminé
    }
};

export const fetchOfferNoticeById = createAsyncThunk(
    "offerNotice/fetchById",
    async (offerId, { dispatch, rejectWithValue }) => {
      try {
        const response = await getOfferNoticeById(offerId);
        console.log(response)
        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const updateOffer = (offerId, updatedOfferData) => async (dispatch) => {
    dispatch(setLoading(true)); // Mettre le chargement à true
    try {
        const response = await updateOfferNotice(offerId, updatedOfferData);
        // Mettre à jour l'offre dans le state si nécessaire
    } catch (error) {
        dispatch(setError(error.message)); // Mettre à jour l'erreur dans le state
    } finally {
        dispatch(setLoading(false)); // Mettre le chargement à false une fois terminé
    }
};

export const deleteOffer = (offerId) => async (dispatch) => {
    dispatch(setLoading(true)); // Mettre le chargement à true
    try {
        const response = await deleteOfferNotice(offerId);
        // Mettre à jour les offres dans le state si nécessaire
    } catch (error) {
        dispatch(setError(error.message)); // Mettre à jour l'erreur dans le state
    } finally {
        dispatch(setLoading(false)); // Mettre le chargement à false une fois terminé
    }
};

export default offerNoticeSlice.reducer;
